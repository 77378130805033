import Image from 'next/image';
import { useMemo, JSX } from 'react';

import { countryCodesWithEmoji } from './contants';
import { useEmojiSupported } from './useEmojiSupported';
import { COUNTRY_CODES } from '../../../constants/countryCodes.constants';

export const useCurrentFlag = (
  currentCodeIndex: number | null,
): JSX.Element | null => {
  const isEmojiSupported = useEmojiSupported();

  const currentFlag = useMemo(
    () =>
      typeof currentCodeIndex === 'number' ? (
        isEmojiSupported ? (
          <span
            role='img'
            aria-label={`flag-${countryCodesWithEmoji[currentCodeIndex].iso2}`}
            className='flex size-8 items-center text-center text-3xl tablet:text-4xl'
          >
            {countryCodesWithEmoji[currentCodeIndex].emoji}
          </span>
        ) : (
          <Image
            width={29}
            height={29}
            src={`/icons/flags/${COUNTRY_CODES[currentCodeIndex].iso2}.svg`}
            className='object-contain object-center'
            alt={COUNTRY_CODES[currentCodeIndex].iso2}
          />
        )
      ) : null,
    [currentCodeIndex, isEmojiSupported],
  );

  return currentFlag;
};
