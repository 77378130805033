import clsx from 'clsx';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import React from 'react';

import {
  useApplyLocaleOnDocument,
  useUtmManager,
  useInitializeSentry,
  useOneSignal,
} from './hooks';
import { GoogleTagManager } from '../../common/GoogleTagManager';
import { PropagateRoistat } from '../../common/PropagateRoistat';
import { VisualWebOptimizer } from '../../common/VisualWebOptimizer';

const ModalsRoot = dynamic(() =>
  import(/* webpackChunkName: "ModalsRoot" */ '../../modals/ModalsRoot').then(
    r => r.ModalsRoot,
  ),
);
const CookieBanner = dynamic(() =>
  import(
    /* webpackChunkName: "CookieBanner" */ '../../common/CookieBanner'
  ).then(r => r.CookieBanner),
);

export type TAppProps = AppProps & {
  className?: string;
};

export const App: React.FC<TAppProps> = ({
  Component,
  className,
  ...restProps
}) => {
  const { isLocaleLoading, locale } = useApplyLocaleOnDocument();
  useUtmManager();
  useInitializeSentry();
  useOneSignal();

  return (
    <div
      id='_app'
      key={locale}
      className={clsx(className, 'flex min-h-screen flex-col')}
    >
      <VisualWebOptimizer />
      <GoogleTagManager />
      <PropagateRoistat />
      {!isLocaleLoading && (
        <>
          <ModalsRoot />
          <CookieBanner />
        </>
      )}
      <Component {...restProps.pageProps} />
    </div>
  );
};
