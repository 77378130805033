import PhoneNumber from 'libphonenumber-js';
import { ChangeEvent, useEffect, useState } from 'react';

import { COUNTRY_CODES } from '../../../constants/countryCodes.constants';

import type { TPhoneInputV2Props } from './PhoneInputV2';

export const usePhoneInput = ({
  onChange,
  onCountryCodeChange,
  ref,
  value,
}: Pick<TPhoneInputV2Props, 'onChange' | 'onCountryCodeChange' | 'value'> & {
  ref: React.RefObject<HTMLInputElement>;
}): {
  currentCodeIndex: number | null;
  currentPhone: string | undefined;
  onChangeCurrentCodeIndex: (newCodeIndex: number | null) => void;
  onChangePhoneNumber: (e: ChangeEvent<HTMLInputElement>) => void;
} => {
  const [currentCodeIndex, setCurrentCodeIndex] = useState<number | null>(null);
  const [currentPhone, setCurrentPhone] = useState((value as string) || '');

  useEffect(() => {
    if (!value) {
      // support reset
      setCurrentPhone('');
      setCurrentCodeIndex(null);
    }
  }, [value]);

  const onChangeCurrentCodeIndex = (newCodeIndex: number | null): void => {
    const dialCode =
      newCodeIndex !== null ? COUNTRY_CODES[newCodeIndex].dialCode : '';

    let newPhone = '';

    // Replace dial code
    if (currentCodeIndex !== null) {
      const oldDialCode = COUNTRY_CODES[currentCodeIndex].dialCode;

      if (oldDialCode) {
        newPhone = currentPhone.replace(oldDialCode, dialCode);
      }
    } else {
      newPhone = `+${dialCode}`;
    }

    ref.current?.focus();
    setCurrentPhone(newPhone);
    setCurrentCodeIndex(newCodeIndex);
    onChange(newPhone);
    onCountryCodeChange?.(dialCode);
  };

  const onChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    let newPhoneNumberValue = e.target.value || '';
    let dialCode = '';

    // Removes non-digit characters
    newPhoneNumberValue = newPhoneNumberValue.replace(/\D/g, '');

    // Add + if not exists
    if (!newPhoneNumberValue.startsWith('+')) {
      newPhoneNumberValue = `+${newPhoneNumberValue}`;
    }

    const phoneNumberParsed = PhoneNumber(newPhoneNumberValue);

    if (phoneNumberParsed) {
      const [possibleCountry] = phoneNumberParsed.getPossibleCountries();

      if (possibleCountry) {
        const index = COUNTRY_CODES.findIndex(
          country =>
            country.iso2.toLocaleUpperCase() ===
            possibleCountry?.toLocaleUpperCase(),
        );
        dialCode = COUNTRY_CODES[index].dialCode;
        setCurrentCodeIndex(index);
      }

      setCurrentPhone(phoneNumberParsed.formatInternational());
    } else {
      setCurrentPhone(newPhoneNumberValue);
    }

    onChange(newPhoneNumberValue);
    onCountryCodeChange(dialCode);
  };

  return {
    currentCodeIndex,
    currentPhone,
    onChangeCurrentCodeIndex,
    onChangePhoneNumber,
  };
};
