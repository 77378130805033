import {
  ESectionVariant,
  TContentSectionProps,
  ContentSection,
} from '@front/shared/ds';
import React from 'react';

import { BlockWithRows } from './BlockWithRows';

import type { Section } from '@shared/master-types';

type TSectionWrapperProps = TContentSectionProps &
  Omit<
    Section['backgroundStyle'],
    | 'background'
    | 'containerVariant'
    | 'containerBackground'
    | 'containerRadiusOnMobile'
  >;

export const SectionWrapper: React.FC<TSectionWrapperProps> = props => {
  const { sectionVariant, children } = props;

  if (sectionVariant === ESectionVariant.BlockWithRows) {
    return <BlockWithRows {...props}>{children}</BlockWithRows>;
  }

  return <ContentSection {...props}>{children}</ContentSection>;
};
