import {
  isRichTextContentEmpty,
  RichText,
  TActionWithText,
  toImageProps,
} from '@front/shared/ds';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { HeroCard } from './HeroCard';
import { EHeroCardTheme } from './constants';
import { useAppDispatch } from '../../../../store/store.hooks';
import prepareCTA from '../../../../utils/prepareCTA';

import type { THeroCardLeftProps } from './HeroCardLeft';
import type { THeroCardRightProps } from './HeroCardRight';
import type { Hero } from '@shared/master-types';

type THeroCardConnectedProps = {
  hero: Hero;
};

export const HeroCardConnected: React.FC<THeroCardConnectedProps> = ({
  hero,
}) => {
  const appDispatch = useAppDispatch();
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');

  const leftCard = useMemo(() => {
    const { richTitle, eyebrowsTop, eyebrowsBottom, subtitle, heroCard } = hero;

    const preparedActions: TActionWithText[] = heroCard?.cta
      ? prepareCTA(heroCard.cta, currentLocale, appDispatch)
      : [];

    const leftCardProps: THeroCardLeftProps = {
      icon: heroCard?.icon ? toImageProps(heroCard.icon) : null,
      title:
        richTitle && !isRichTextContentEmpty(richTitle) ? (
          <RichText content={richTitle} />
        ) : null,
      description: subtitle,
      eyebrowsTop: (eyebrowsTop || []).map(data => data.text),
      eyebrowsBottom: (eyebrowsBottom || []).map(data => data.text),
      actions: preparedActions,
      theme: heroCard?.theme as EHeroCardTheme,
    };

    return leftCardProps;
  }, [hero, appDispatch, currentLocale]);

  const rightAnimation = useMemo(() => {
    const { heroCard } = hero;

    const rightAnimationProps: THeroCardRightProps = {
      image: heroCard?.animation?.image
        ? toImageProps(heroCard?.animation?.image)
        : null,
    };

    return rightAnimationProps;
  }, [hero]);

  return <HeroCard leftCard={leftCard} rightAnimation={rightAnimation} />;
};
