import { InputV2, PhoneInputV2 } from '@front/shared/ds';
import { Form } from '@shared/master-types';
import React from 'react';
import {
  Control,
  Controller,
  UseFormClearErrors,
  UseFormRegister,
} from 'react-hook-form';

import { COUNTRY_CODE_FIELD_NAME } from '../FormInputParser/FormInputParser.constants';

export type TFormInputParserProps = {
  field: Required<Form>['fields'][number];
  register: UseFormRegister<Record<string, unknown>>;
  control: Control;
  error?: string;
  hasBorder?: boolean;
  clearErrors?: UseFormClearErrors<Record<string, unknown>>;
};

const FormInputParserV2: React.FC<TFormInputParserProps> = props => {
  const { field, register, control, error, hasBorder, clearErrors } = props;

  const defaultPhoneValidationTextError =
    'The phone number must contain 7–15 characters';

  const required = field.required && {
    value: field.required,
    message: field.requiredTextError,
  };

  switch (field.preset) {
    case 'email': {
      const inputProps = register(field.name, {
        required,
        pattern: {
          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/,
          message: field.validationTextError,
        },
      });
      const origOnChange = inputProps.onChange;
      inputProps.onChange = e => {
        clearErrors?.('EMAIL');
        e.target.value = e.target.value.trim();
        return origOnChange(e);
      };
      return (
        <InputV2
          {...inputProps}
          key={field.id}
          required={field.required}
          placeholder={field.placeholder}
          error={error}
          type='text'
          inputMode='email'
          autoComplete='email'
          hasBorder={hasBorder}
        />
      );
    }
    case 'fullName': {
      const inputProps = register(field.name, {
        required,
        validate: value => {
          if (typeof value !== 'string' || value.length > 100) {
            return field.validationTextError;
          }
          return (
            value.trim().length > 0 ||
            field.validationTextError ||
            'Full name cannot be empty or just spaces'
          );
        },
      });
      const origOnChange = inputProps.onChange;
      inputProps.onChange = e => {
        clearErrors?.('FULLNAME');
        e.target.value = e.target.value.trim();
        return origOnChange(e);
      };
      return (
        <InputV2
          {...inputProps}
          key={field.id}
          required={field.required}
          placeholder={field.placeholder}
          error={error}
          type='text'
          maxLength={100}
          autoComplete='name'
          hasBorder={hasBorder}
        />
      );
    }
    case 'phone': {
      const { onChange, name } = register(COUNTRY_CODE_FIELD_NAME);
      const onCountryCodeChange = (dialCode: string): void => {
        void onChange({
          type: 'change',
          target: {
            name,
            value: dialCode,
          },
        });
      };

      return (
        <Controller
          name={field.name}
          control={control}
          rules={{
            required,
            validate: value => {
              const digitsOnly = value.replace(/\D/g, ''); // Removes non-digit characters
              return (
                (digitsOnly.length >= 7 && digitsOnly.length <= 15) ||
                field.validationTextError ||
                defaultPhoneValidationTextError
              );
            },
          }}
          render={({ field: fieldProps }) => {
            const origOnChange = fieldProps.onChange;
            fieldProps.onChange = e => {
              clearErrors?.('PHONE');
              return origOnChange(e);
            };

            return (
              <PhoneInputV2
                {...fieldProps}
                error={error}
                placeholder={field.label}
                onCountryCodeChange={onCountryCodeChange}
                required={field.required}
                hasBorder={hasBorder}
                type='text'
                inputMode='tel'
                autoComplete='tel'
              />
            );
          }}
        />
      );
    }

    default:
      return <></>;
  }
};

export default FormInputParserV2;
