import { Breadcrumbs } from '@front/shared/ds';
import React, { ReactNode } from 'react';

import {
  FooterParser,
  HeaderParser,
  TMetaProps,
  Meta,
  Theme,
} from '../../../components';
import {
  useGetFooterQuery,
  useGetHeaderQuery,
  useGetMetaQuery,
  useGetLocalesQuery,
  useGetPageQuery,
} from '../../../features';

import type { Page } from '@shared/master-types';

type TPageLayoutProps = {
  locale: string;
  draft?: boolean;
  headerDepth?: number;
  pageQueryDepth?: number;
  children: ReactNode;
  meta?: Partial<TMetaProps>;
  breadcrumbs?: Page['breadcrumbs'];
  pageId?: string;
};

export const PageLayout: React.FC<TPageLayoutProps> = props => {
  const {
    draft,
    locale,
    children,
    pageQueryDepth,
    meta: metaProps,
    breadcrumbs,
    pageId,
    headerDepth,
  } = props;

  const { data: headerDefaultObject } = useGetHeaderQuery({
    locale,
    depth: headerDepth,
  });

  const { data: footerDefaultObject } = useGetFooterQuery({ locale });
  const { data: localesObject } = useGetLocalesQuery({
    locale,
    draft,
  });

  const { data: meta } = useGetMetaQuery({ locale });
  const { data: { navMenu, parts } = {} } = useGetPageQuery(
    { id: pageId ?? '', draft, locale, depth: pageQueryDepth },
    {
      skip: !pageId,
    },
  );

  const locales = localesObject?.docs;
  const header = headerDefaultObject?.docs?.[0];
  const footer = footerDefaultObject?.docs?.[0];
  const pageTheme = parts?.pageTheme;

  return (
    <>
      {!!pageTheme && <Theme pageTheme={pageTheme} />}

      {!!meta && <Meta {...meta} {...metaProps} />}

      {!!header && (
        <HeaderParser headers={header} locales={locales} navMenu={navMenu} />
      )}

      <main className='flex-1'>
        {!!breadcrumbs?.length && (
          <Breadcrumbs
            items={breadcrumbs}
            locale={locales?.length ? locale : ''}
          />
        )}
        {children}
      </main>

      {footer && <FooterParser footers={footer} locales={locales} />}
    </>
  );
};
