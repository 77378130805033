import { useEffect, useState } from 'react';

import { isEmojiFlagSupported } from './utils';

export const useEmojiSupported = (): boolean => {
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    setIsSupported(isEmojiFlagSupported());
  }, []);

  return isSupported;
};
