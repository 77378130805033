import { getMediaSrc } from '@front/shared/ds';
import React, { useEffect, useRef } from 'react';

import { VideoResponsive } from '../../../common';

import type { Media } from '@shared/master-types';
import type { ImageProps } from 'next/image';

type TMediaSlideProps = {
  video: Media | string;
  image?: ImageProps | null;
  isActive?: boolean;
};

export const MediaSlideVideo: React.FC<TMediaSlideProps> = ({
  video,
  image,
  isActive,
}) => {
  const ref = useRef<HTMLVideoElement>(null);

  let poster = image ? String(image.src) : '';
  poster = getMediaSrc(poster) || '';

  useEffect(() => {
    const videoRef = ref.current;

    if (videoRef) {
      if (isActive) {
        videoRef.play().catch(err => {
          // eslint-disable-next-line no-console
          console.log('Failed to play video', err);
        });
      } else {
        videoRef.pause();
      }
    }
  }, [isActive]);

  return (
    <VideoResponsive
      ref={ref}
      loop
      playsInline
      muted
      autoPlay
      video={video}
      className='h-full w-full object-cover object-top'
      preload='none'
      poster={poster}
    />
  );
};
