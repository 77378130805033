import { useAutoAnimate } from '@formkit/auto-animate/react';
import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface IInputV2Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  error?: string;
  startAdornment?: React.JSX.Element | null;
  endAdornment?: React.JSX.Element | null;
  hasBorder?: boolean;
}

const InputV2 = React.forwardRef<HTMLInputElement, IInputV2Props>(
  (props, ref) => {
    const {
      placeholder,
      required,
      id,
      error,
      className,
      startAdornment,
      endAdornment,
      hasBorder,
      ...restProps
    } = props;

    const [parent] = useAutoAnimate<HTMLDivElement>();
    const [wrapper] = useAutoAnimate<HTMLDivElement>();

    return (
      <div ref={parent} className='grid'>
        <div
          ref={wrapper}
          className={twMerge(
            clsx(
              'grid h-16 rounded-lg bg-surface-50-input focus-within:border focus-within:border-control-600 hover:focus-within:border-control-600 tablet:h-[4.5rem] desktop:h-[5.5rem]',
              {
                'border border-transparent': !hasBorder,
                'rounded-bl-none rounded-br-none': error,
                'border border-control-150 hover:border-control-250': hasBorder,
                'border-control-600': error && hasBorder,
              },
              className,
            ),
          )}
        >
          <div className='flex'>
            {startAdornment && (
              <div className='flex items-center  border-e border-surface-150 px-3 tablet:px-4 desktop:px-6'>
                <span className='flex w-10 items-center justify-center'>
                  {startAdornment}
                </span>
              </div>
            )}
            <input
              id={id}
              ref={ref}
              {...restProps}
              placeholder={placeholder}
              required={required}
              className={clsx(
                'peer/input placeholder:interface-400 w-full bg-transparent p-0 px-4 text-lg font-normal leading-relaxed caret-interface-1000 focus:outline-none tablet:px-6 tablet:text-xl tablet:leading-extra-loose desktop:text-3xl desktop:leading-loose',
                error ? 'text-error' : 'text-interface-1000',
              )}
            />
            {endAdornment && (
              <div className='flex items-center border-s border-surface-150 px-3 tablet:px-4 desktop:px-6'>
                <span className='flex w-10 items-center justify-center'>
                  {endAdornment}
                </span>
              </div>
            )}
          </div>
        </div>
        {error && (
          <label className='flex items-center rounded-bl-lg rounded-br-lg bg-[#353535] px-4 py-2.5 text-sm leading-loose text-white desktop:px-6'>
            {error}
          </label>
        )}
      </div>
    );
  },
);

InputV2.displayName = 'InputV2';

export default InputV2;
