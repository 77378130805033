import { cva } from 'cva';

export const enum EStorySize {
  SizeM = 'SizeM',
  SizeL = 'SizeL',
}

export const enum EStoryColor {
  Primary = 'Primary',
  PrimaryOnColor = 'PrimaryOnColor',
}

export const storyCVA = cva(
  'flex flex-col justify-end border-2 overflow-hidden relative cursor-pointer transition-colors group after:content-[""] after:bg-interface-50/20 after:absolute after:inset-0 after:transition-transform after:ease-linear after:z-0 after:w-full after:h-full shrink-0',
  {
    variants: {
      size: {
        [EStorySize.SizeM]: 'ps-3 pe-2 py-2 h-[90px] w-[90px] rounded-3xl',
        [EStorySize.SizeL]: 'p-4 h-[154px] w-[154px] rounded-4xl',
      },
      color: {
        [EStoryColor.Primary]: '',
        [EStoryColor.PrimaryOnColor]: '',
      },
      isAnimated: {
        true: 'after:duration-[3000ms] after:translate-x-0',
        false: 'after:-translate-x-full after:duration-0',
      },
      isSelected: {
        true: '',
      },
    },
    compoundVariants: [
      {
        isSelected: true,
        color: EStoryColor.PrimaryOnColor,
        className: 'border-interface-50',
      },
      {
        isSelected: false,
        color: EStoryColor.PrimaryOnColor,
        className: 'border-interface-50/30 hover:border-interface-50/60',
      },
      {
        isSelected: true,
        color: EStoryColor.Primary,
        className: 'border-control-1000',
      },
      {
        isSelected: false,
        color: EStoryColor.Primary,
        className: 'border-control-200 hover:border-control-1000',
      },
    ],
  },
);

export const titleCVA = cva('line-clamp-3 break-words relative z-[2]', {
  variants: {
    size: {
      [EStorySize.SizeM]: 'text-sm font-semibold leading-extra-loose',
      [EStorySize.SizeL]: 'text-lg font-medium leading-normal',
    },
    color: {
      [EStoryColor.Primary]: 'text-interface-1000',
      [EStoryColor.PrimaryOnColor]: 'text-interface-50',
    },
  },
});
export const iconCVA = cva(
  'absolute z-[1] object-contain object-center transition-opacity',
  {
    variants: {
      size: {
        [EStorySize.SizeM]: '-top-4 end-0 h-18 w-18',
        [EStorySize.SizeL]: '-end-3 -top-11 h-[8.75rem] w-[8.75rem]',
      },
      color: {
        [EStoryColor.Primary]: 'group-hover:opacity-100 opacity-10',
        [EStoryColor.PrimaryOnColor]: 'group-hover:opacity-60 opacity-30',
      },
      isSelected: {
        true: '!opacity-100',
      },
    },
  },
);
