import {
  B2copyTradingPlatformConstants,
  B2CoreConstants,
  LiquidityConstants,
  MainConstants,
  PropTradingConstants,
} from './ThemeColors';

export enum EThemeTemplates {
  MainPage = 'mainPage',
  Liquidity = 'liquidity',
  PropTrading = 'propTrading',
  B2Core = 'b2core',
  B2copyTradingPlatform = 'b2copyTradingPlatform',
}

export const themeColorsMap: Record<
  EThemeTemplates,
  { colorName: string; color: string }[]
> = {
  [EThemeTemplates.MainPage]: MainConstants,
  [EThemeTemplates.Liquidity]: LiquidityConstants,
  [EThemeTemplates.PropTrading]: PropTradingConstants,
  [EThemeTemplates.B2Core]: B2CoreConstants,
  [EThemeTemplates.B2copyTradingPlatform]: B2copyTradingPlatformConstants,
};
