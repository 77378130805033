import { ImageWithPlaceholder } from '@front/shared/ds';
import React from 'react';

import type { ImageProps } from 'next/image';

export type THeroCardRightProps = {
  image?: ImageProps | null;
};

export const HeroCardRight: React.FC<THeroCardRightProps> = ({ image }) => {
  return (
    <div className='h-full overflow-hidden rounded-4xl border-2 border-surface-200'>
      {image && <ImageWithPlaceholder {...image} className='w-full' />}
    </div>
  );
};
