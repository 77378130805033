import { Container, EContainerVariant } from '@front/shared/ds';
import React from 'react';

import { HeroCardLeft, THeroCardLeftProps } from './HeroCardLeft';
import { HeroCardRight, THeroCardRightProps } from './HeroCardRight';

type THeroCardProps = {
  leftCard: THeroCardLeftProps;
  rightAnimation: THeroCardRightProps;
};

export const HeroCard: React.FC<THeroCardProps> = ({
  leftCard,
  rightAnimation,
}) => {
  return (
    <Container variant={EContainerVariant.Main}>
      <div className='flex flex-col gap-4 py-6 md:py-10 lg:flex-row'>
        <div className='flex-1'>
          <HeroCardLeft {...leftCard} />
        </div>

        <div className='hidden w-[400px] lg:block'>
          <HeroCardRight {...rightAnimation} />
        </div>
      </div>
    </Container>
  );
};
