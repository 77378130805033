import React, { ComponentProps, useRef } from 'react';

import { useCurrentFlag } from './useCurrentFlag';
import { usePhoneCodesOptions } from './usePhoneCodesOptions';
import { usePhoneInput } from './usePhoneInput';
import { ReactComponent as IconGlob } from '../../../../public/icons/components/formV2/globe.svg';
import { IInputV2Props, InputV2 } from '../InputV2';
import { ESelectVariant, Select } from '../Select';

export type TPhoneInputV2Props = {
  onChange: (value: string) => void;
  required?: boolean;
  onCountryCodeChange: (code: string) => void;
} & Partial<ComponentProps<typeof InputV2>>;

const PhoneInputV2: React.FC<TPhoneInputV2Props> = props => {
  const {
    placeholder,
    value,
    onChange,
    onCountryCodeChange,
    ...restInputProps
  } = props;

  const ref = useRef<HTMLInputElement>(null);

  const {
    currentCodeIndex,
    currentPhone,
    onChangeCurrentCodeIndex,
    onChangePhoneNumber,
  } = usePhoneInput({
    onChange,
    onCountryCodeChange,
    ref,
    value,
  });
  const currentFlag = useCurrentFlag(currentCodeIndex);
  const { Options, valueOptions } = usePhoneCodesOptions();

  // Prevent validation(blur) on flag select click
  const onBlur: IInputV2Props['onBlur'] = e => {
    if (e.relatedTarget?.classList?.contains?.(ESelectVariant.PhoneInput)) {
      e.stopPropagation();
    } else {
      restInputProps.onBlur?.(e);
    }
  };

  return (
    <InputV2
      ref={ref}
      {...restInputProps}
      placeholder={placeholder}
      value={currentPhone}
      onChange={onChangePhoneNumber}
      onBlur={onBlur}
      startAdornment={
        <Select
          variant={ESelectVariant.PhoneInput}
          value={currentCodeIndex}
          onChange={onChangeCurrentCodeIndex}
          options={valueOptions}
          icon={
            currentFlag || <IconGlob className='aspect-square size-[30px]' />
          }
          showArrowDown={false}
        >
          {Options}
        </Select>
      }
    />
  );
};

export default PhoneInputV2;
