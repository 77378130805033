import emojiSupport from 'detect-emoji-support';

export function getFlagEmoji(countryCode: string): string {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

export function isEmojiFlagSupported(): boolean {
  return emojiSupport();
}
