const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  colors: {
    current: 'currentColor',
    transparent: 'transparent',
    error: '#DE2A3C',
    success: '#2CC875',
    white: '#FFFFFF',
    black: '#121212',
    interface: {
      50: 'rgb(var(--color-interface-50) / <alpha-value>)',
      400: 'rgb(var(--color-interface-400) / <alpha-value>)',
      500: 'rgb(var(--color-interface-500) / <alpha-value>)',
      600: 'rgb(var(--color-interface-600) / <alpha-value>)',
      700: 'rgb(var(--color-interface-700) / <alpha-value>)',
      800: 'rgb(var(--color-interface-800) / <alpha-value>)',
      1000: 'rgb(var(--color-interface-1000) / <alpha-value>)',
    },
    'light-interface': {
      500: 'rgb(var(--color-light-interface-500) / <alpha-value>)',
      800: 'rgb(var(--color-light-interface-800) / <alpha-value>)',
      1000: 'rgb(var(--color-light-interface-1000) / <alpha-value>)',
    },
    surface: {
      50: {
        DEFAULT: 'rgb(var(--color-surface-50) / <alpha-value>)',
        input: 'rgb(var(--color-surface-50-input) / <alpha-value>)',
        dropdown: 'rgb(var(--color-surface-50-dropdown) / <alpha-value>)',
        tooltip: 'rgb(var(--color-surface-50-tooltip) / <alpha-value>)',
      },
      100: {
        DEFAULT: 'rgb(var(--color-surface-100) / <alpha-value>)',
        input: 'rgb(var(--color-surface-100-input) / <alpha-value>)',
      },
      150: 'rgb(var(--color-surface-150) / <alpha-value>)',
      200: 'rgb(var(--color-surface-200) / <alpha-value>)',
      800: 'rgb(var(--color-surface-800) / <alpha-value>)',
      950: 'rgb(var(--color-surface-950) / <alpha-value>)',
      1000: 'rgb(var(--color-surface-1000) / <alpha-value>)',
    },
    'light-surface': {
      100: 'rgb(var(--color-light-surface-100) / <alpha-value>)',
      200: 'rgb(var(--color-light-surface-200) / <alpha-value>)',
    },
    control: {
      50: 'rgb(var(--color-control-50) / <alpha-value>)',
      100: 'rgb(var(--color-control-100) / <alpha-value>)',
      150: 'rgb(var(--color-control-150) / <alpha-value>)',
      200: 'rgb(var(--color-control-200) / <alpha-value>)',
      250: 'rgb(var(--color-control-250) / <alpha-value>)',
      600: 'rgb(var(--color-control-600) / <alpha-value>)',
      650: 'rgb(var(--color-control-650) / <alpha-value>)',
      950: 'rgb(var(--color-control-950) / <alpha-value>)',
      1000: 'rgb(var(--color-control-1000) / <alpha-value>)',
    },
    'light-control': {
      50: 'rgb(var(--color-light-control-50) / <alpha-value>)',
      100: 'rgb(var(--color-light-control-100) / <alpha-value>)',
      600: 'rgb(var(--color-light-control-600) / <alpha-value>)',
      650: 'rgb(var(--color-light-control-650) / <alpha-value>)',
      950: 'rgb(var(--color-light-control-950) / <alpha-value>)',
    },
    brand: {
      50: 'rgb(var(--color-brand-50) / <alpha-value>)',
      100: 'rgb(var(--color-brand-100) / <alpha-value>)',
      250: 'rgb(var(--color-brand-250) / <alpha-value>)',
      300: 'rgb(var(--color-brand-300) / <alpha-value>)',
      500: 'rgb(var(--color-brand-500) / <alpha-value>)',
      550: 'rgb(var(--color-brand-550) / <alpha-value>)',
      1000: 'rgb(var(--color-brand-1000) / <alpha-value>)',
    },
    accent: {
      500: 'rgb(var(--color-accent-500) / <alpha-value>)',
      550: 'rgb(var(--color-accent-550) / <alpha-value>)',
      800: 'rgb(var(--color-accent-800) / <alpha-value>)',
      850: 'rgb(var(--color-accent-850) / <alpha-value>)',
    },
  },
  screens: {
    sm: '320px',
    md: '641px',
    lg: '769px',
    xl: '1025px',
    '2xl': '1281px',
    mobile: '320px',
    tablet: '688px',
    desktop: '1024px',
  },

  container: {
    center: true,
    padding: {
      DEFAULT: '1rem',
      lg: '0.75rem',
      xl: '5.75rem',
      '2xl': '7.25rem',
      '3xl': '9.5rem',
    },
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.375rem',
    '3xl': '1.5rem',
    '4xl': '1.75rem',
    '5xl': '2rem',
    '6xl': '2.25rem',
    '7xl': '2.5rem',
    '8xl': '3rem',
    '9xl': '3.75rem',
    '10xl': '4rem',
  },
  lineHeight: {
    tight: '1.1',
    snug: '1.15',
    normal: '1.2',
    relaxed: '1.25',
    loose: '1.35',
    'extra-loose': '1.45',
    'mega-loose': '1.65',
  },
  extend: {
    height: {
      15: '3.75rem',
      35: '8.75rem',
    },
    width: {
      29: '7.25rem',
      30: '7.5rem',
    },
    spacing: {
      17: '4.25rem',
      18: '4.5rem',
      22: '5.75rem',
      46: '11.5rem',
    },
    keyframes: {
      'translate-up': {
        '0%': { transform: 'translateY()' },
        '100%': { transform: 'translateY(-100%)' },
      },
      marquee: {
        '0%': { transform: 'translateX(0)' },
        '100%': { transform: 'translateX(-50%)' },
      },
    },
    animation: {
      'translate-up': 'translate-up .3s .1s forwards ease-in-out',
      marquee: 'marquee 30s linear infinite',
    },
    container: {
      center: true,
    },
    fontFamily: {
      sans: ['Inter', ...defaultTheme.fontFamily.sans],
    },
    borderRadius: {
      '4xl': '2rem',
      '5xl': '2.5rem',
    },
  },
  plugins: [require('tailwind-scrollbar')({ nocompatible: true })],
};
