import { TelegramPost } from '@shared/master-types';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { PaginatedDocs } from 'payload/dist/mongoose/types';

import { baseApi } from '../../store/store.constants';

export const blogPostsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: build => ({
    getTelegramPosts: build.query<
      PaginatedDocs<TelegramPost>,
      {
        depth?: number;
        locale?: string;
        draft?: boolean;
      }
    >({
      query: ({ ...params }) => ({
        url: '/telegram-posts/',
        params,
      }),
    }),
  }),
});

export const {
  endpoints: { getTelegramPosts },
  useGetTelegramPostsQuery,
} = blogPostsApi;
