import { EStoryColor, IconChevronLeft, Story } from '@front/shared/ds';
import React, { useRef, useEffect, useCallback } from 'react';
import { useIntersection } from 'react-use';
import { Navigation, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';

import type { ImageProps } from 'next/image';

type TSliderCard = {
  title?: string;
  icon?: ImageProps | null;
};

type TSliderProps = {
  sliderCards: TSliderCard[];
  activeIndex: number;
  onClickSlide: (index: number) => void;
};

export const Slider: React.FC<TSliderProps> = ({
  sliderCards,
  activeIndex,
  onClickSlide,
}) => {
  const leftRef = useRef<HTMLButtonElement>(null);
  const rightRef = useRef<HTMLButtonElement>(null);
  const sliderRef = useRef<SwiperRef>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(wrapperRef, { threshold: 1 });

  const hasIntersection =
    !!intersection && intersection.intersectionRatio === 1;

  const onClickSwiperSlide = useCallback(
    (index: number): void => {
      if (!sliderRef.current) {
        return;
      }
      sliderRef.current.swiper.slideTo(index);
      if (onClickSlide) {
        onClickSlide(index);
      }
    },
    [onClickSlide],
  );

  useEffect(() => {
    let animationTimeout = null;

    const changeSlide = (): void => {
      const nextIndex = (activeIndex + 1) % sliderCards.length;

      onClickSwiperSlide(nextIndex);
    };

    if (sliderCards.length > 1 && hasIntersection) {
      animationTimeout = setTimeout(changeSlide, 10000);
    }

    return () => {
      if (animationTimeout) {
        clearTimeout(animationTimeout);
      }
    };
  }, [activeIndex, onClickSwiperSlide, sliderCards.length, hasIntersection]);

  return (
    <div className='flex flex-row items-center justify-center gap-4 xl:justify-start'>
      <button ref={leftRef} className='hidden md:block'>
        <IconChevronLeft width={16} height={16} className='shrink-0' />
      </button>

      <div className='min-w-0' ref={wrapperRef}>
        <Swiper
          ref={sliderRef}
          className='-mx-4 max-w-[536px] px-4 md:mx-0 md:max-w-[504px] md:px-0 xl:max-w-[404px] 2xl:max-w-[504px]'
          slidesPerView='auto'
          spaceBetween={sliderCards.length > 1 ? 12 : 0}
          modules={[Navigation, Mousewheel]}
          navigation={{
            nextEl: rightRef.current,
            prevEl: leftRef.current,
            disabledClass: 'invisible',
            hiddenClass: 'invisible',
            lockClass: 'invisible',
          }}
          mousewheel={true}
          loop={false}
          pagination={false}
          scrollbar={false}
        >
          {sliderCards.map((slide, index) => (
            <SwiperSlide key={index} className='w-fit'>
              <Story
                title={slide.title}
                icon={slide.icon}
                color={EStoryColor.PrimaryOnColor}
                selected={activeIndex === index}
                hasAnimation={
                  hasIntersection &&
                  activeIndex === index &&
                  sliderCards.length > 1
                }
                className={
                  hasIntersection && activeIndex === index
                    ? 'after:duration-[10000ms]'
                    : ''
                }
                onClick={() => onClickSwiperSlide(index)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <button ref={rightRef} className='hidden md:block'>
        <IconChevronLeft
          width={16}
          height={16}
          className='shrink-0 rotate-180'
        />
      </button>
    </div>
  );
};
