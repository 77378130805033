import clsx from 'clsx';
import React from 'react';

import {
  EEyebrowSize,
  EEyebrowTheme,
  EEyebrowVariant,
  Eyebrow,
} from '../../../atoms/Eyebrow';

type TEyebrowsProps = {
  eyebrows: string[] | undefined;
  className?: string;
  alignCenter?: boolean;
  hasMarginBottom?: boolean;
  theme?: EEyebrowTheme;
  variant?: EEyebrowVariant;
  size?: EEyebrowSize;
};
const Eyebrows: React.FC<TEyebrowsProps> = props => {
  const {
    eyebrows,
    hasMarginBottom = false,
    alignCenter = false,
    theme = EEyebrowTheme.Primary,
    variant = EEyebrowVariant.Text,
    size = EEyebrowSize.Medium,
    className,
  } = props;

  if (!eyebrows?.length) {
    return null;
  }

  return (
    <div
      className={clsx('flex flex-wrap gap-4', {
        'justify-center text-center': alignCenter,
        'lg:mb-2': hasMarginBottom,
      })}
    >
      {eyebrows.map((eyebrow, index) => (
        <Eyebrow
          key={`${eyebrow}-${index}`}
          size={size}
          variant={variant}
          theme={theme}
          className={className}
        >
          {eyebrow}
        </Eyebrow>
      ))}
    </div>
  );
};

export default Eyebrows;
