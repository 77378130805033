import { ImageWithPlaceholder } from '@front/shared/ds';
import React from 'react';

import { Mask } from './Mask';
import { MediaSlideVideo } from './MediaSlideVideo';

import type { Media } from '@shared/master-types';
import type { ImageProps } from 'next/image';

export type TMediaSlide = {
  image?: ImageProps | null;
  video?: Media | string | null;
  hasMask?: boolean;
};

type TMediaSlideProps = {
  slide: TMediaSlide;
  isActive?: boolean;
};

export const MediaSlide: React.FC<TMediaSlideProps> = ({ slide, isActive }) => {
  return (
    <>
      {slide.video ? (
        <div className='absolute bottom-0 left-0 h-full w-full'>
          <MediaSlideVideo
            video={slide.video}
            image={slide.image}
            isActive={isActive}
          />
        </div>
      ) : null}

      {!slide.video && slide.image ? (
        <div className='absolute bottom-0 left-0 h-full w-full'>
          <ImageWithPlaceholder
            {...slide.image}
            loading='lazy'
            className='h-full w-full object-cover'
          />
        </div>
      ) : null}

      {slide.hasMask ? <Mask /> : null}
    </>
  );
};
