import { EWebsiteType } from '@front/shared';
import { ESectionVariant } from '@front/shared/ds';
import dynamic from 'next/dynamic';
import React from 'react';

import type { Jurisdiction, Locale, Section } from '@shared/master-types';

const AccordionsGridConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "AccordionsGridConnected" */ '../../blocks/AccordionsGridConnected/AccordionsGridConnected'
    ),
);
const StatsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "StatsConnected" */ '../../blocks/StatsConnected'
    ),
);
const StatsV2Connected = dynamic(
  () =>
    import(
      /* webpackChunkName: "StatsV2Connected" */ '../../blocks/StatsV2Connected'
    ),
);
const CountriesRowConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "CountriesRowConnected" */ '../../blocks/CountriesRowConnected'
    ),
);
const ContactsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "ContactsConnected" */ '../../blocks/ContactsConnected'
    ),
);
const ConnectionSchemeConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "ConnectionSchemeConnected" */ '../../blocks/ConnectionSchemeConnected'
    ),
);
const FeaturesGridConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "FeaturesGridConnected" */ '../../blocks/FeaturesGridConnected'
    ),
);
const TabbedCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "TabbedCardsConnected" */ '../../blocks/TabbedCardsConnected'
    ),
);
const TitleBasicCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "TitleBasicCardsConnected" */ '../../blocks/TitleBasicCardsConnected'
    ),
);
const TitleBgCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "TitleBgCardsConnected" */ '../../blocks/TitleBgCardsConnected'
    ),
);
const LogoCloudConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "LogoCloudConnected" */ '../../blocks/LogoCloudConnected'
    ),
);
const HorizontalCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "HorizontalCardsConnected" */ '../../blocks/HorizontalCardsConnected/HorizontalCardsConnected'
    ),
);
const HorizontalCardsV2Connected = dynamic(
  () =>
    import(
      /* webpackChunkName: "HorizontalCardsV2Connected" */ '../../blocks/HorizontalCardsV2Connected/HorizontalCardsV2Connected'
    ),
);
const HorizontalCardsBgV2Connected = dynamic(
  () =>
    import(
      /* webpackChunkName: "HorizontalCardsBgV2Connected" */ '../../blocks/HorizontalCardsBgV2Connected'
    ),
);
const BannerCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "BannerCardsConnected" */ '../../blocks/BannerCardsConnected'
    ),
);
const BannerCardsBgV2Connected = dynamic(
  () =>
    import(
      /* webpackChunkName: "BannerCardsBgV2Connected" */ '../../blocks/BannerCardsBgV2Connected'
    ),
);
const DefaultCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "DefaultCardsConnected" */ '../../blocks/DefaultCardsConnected'
    ),
);
const DefaultCardsV2Connected = dynamic(
  () =>
    import(
      /* webpackChunkName: "DefaultCardsV2Connected" */ '../../blocks/DefaultCardsV2Connected'
    ),
);
const BrandCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "BrandCardsConnected" */ '../../blocks/BrandCardsConnected'
    ),
);
const BrandCardsBgV2Connected = dynamic(
  () =>
    import(
      /* webpackChunkName: "BrandCardsBgV2Connected" */ '../../blocks/BrandCardsBgV2Connected'
    ),
);
const BannerListConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "BannerListConnected" */ '../../blocks/BannerListConnected'
    ),
);

const FormSlotConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "FormSlotConnected" */ '../../blocks/FormSlotConnected'
    ),
);

const FormSlotV2Connected = dynamic(
  () =>
    import(
      /* webpackChunkName: "FormSlotConnected" */ '../../blocks/FormSlotV2Connected'
    ),
);

const CurrencyListConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "CurrencyListConnected" */ '../../blocks/CurrencyListConnected'
    ),
);

const DocumentsListConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "DocumentsListConnected" */ '../../blocks/DocumentsListConnected'
    ),
);

const RecentArticlesConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "RecentArticlesConnected" */ '../../blocks/RecentArticlesConnected'
    ),
);
const NewsBlockConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "NewsBlockConnected" */ '../../blocks/NewsBlockConnected'
    ),
);
const EventCardRowConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "EventCardRowConnected" */ '../../blocks/EventCardRowConnected'
    ),
);
const VideosBlockConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "VideosConnected" */ '../../blocks/VideosBlockConnected'
    ),
);

const VideoPlayerListConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "VideoPlayerListConnected" */ '../../blocks/VideoPlayerListConnected'
    ),
);

const FinanceTableConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "FinanceTableConnected" */ '../../blocks/FinanceTableConnected'
    ),
);

const PricingPlanConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "PricingPlanConnected" */ '../../blocks/PricingPlanConnected'
    ),
);

const ChartScrollableConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "ChartScrollableConnected" */ '../../blocks/ChartScrollableConnected'
    ),
);

const VerticalCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "VerticalCardsConnected" */ '../../blocks/VerticalCardsConnected'
    ),
);

const QuoteCardConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "QuoteCardConnected" */ '../../blocks/QuoteCardConnected'
    ),
);

const TreeDiagramConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "TreeDiagramConnected" */ '../../blocks/TreeDiagramConnected'
    ),
);

const BannerCardWithTitleCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "BannerCardWithTitleCardsConnected" */ '../../blocks/BannerCardWithTitleCardsConnected'
    ),
);

const PlatesDefaultCardConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "PlatesDefaultCardConnected" */ '../../blocks/PlatesDefaultCardConnected'
    ),
);

const CompanyRankingsListConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "CompanyRankingsListConnected" */ '../../blocks/CompanyRankingsListConnected'
    ),
);

const PartnersWidgetConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "PartnersWidgetConnected" */ '../../blocks/PartnersWidgetConnected'
    ),
);

const PartnersWidgetCustomTabsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "PartnersWidgetCustomTabsConnected" */ '../../blocks/PartnersWidgetCustomTabsConnected'
    ),
);

const AccordionCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "AccordionCardsConnected" */ '../../blocks/AccordionCardsConnected'
    ),
);

const CardTableMixedConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "CardTableMixedConnected" */ '../../blocks/CardTableMixedConnected'
    ),
);

const CardsWithTableConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "CardsWithTableConnected" */ '../../blocks/CardsWithTableConnected'
    ),
);

const ListOfTitlesConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "ListOfTitlesConnected" */ '../../blocks/ListOfTitlesConnected'
    ),
);

const SubHeadingConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "SubHeadingConnected" */ '../../blocks/SubHeadingConnected'
    ),
);

const AccordionCardsV2Connected = dynamic(
  () =>
    import(
      /* webpackChunkName: "AccordionCardsV2Connected" */ '../../blocks/AccordionCardsV2Connected/AccordionCardsV2Connected'
    ),
);

const CardWithLogoRowConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "CardWithLogoRowConnected" */ '../../blocks/CardWithLogoRowConnected/CardWithLogoRowConnected'
    ),
);

const CardWithQuoteRowConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "CardWithQuoteRowConnected" */ '../../blocks/CardWithQuoteRowConnected/CardWithQuoteRowConnected'
    ),
);

const CardWithStoriesConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "CardWithStoriesConnected" */ '../../blocks/CardWithStoriesConnected'
    ),
);

const NewsCardRowWPConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "NewsCardRowWPConnected" */ '../../blocks/NewsCardRowWPConnected/NewsCardRowWPConnected'
    ),
);
const CellWithLinksRowConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "CellWithLinksRowConnected" */ '../../blocks/CellWithLinksRowConnected/CellWithLinksRowConnected'
    ),
);

const HeroSectionTitleRowConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "HeroSectionTitleRowConnected" */ '../../blocks/HeroSectionTitleRowConnected/HeroSectionTitleRowConnected'
    ),
);

const AdditionalButtonConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "AdditionalButtonConnected" */ '../../blocks/AdditionalButtonConnected/AdditionalButtonConnected'
    ),
);
const ProductCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "ProductCardsConnected" */ '../../blocks/ProductCardsConnected'
    ),
);

const TextsNewsRowWPConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "TextsNewsRowWPConnected" */ '../../blocks/TextsNewsRowWPConnected/TextsNewsRowWPConnected'
    ),
);

const TextsExpoWebinarsRowWPConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "TextsExpoWebinarsRowWPConnected" */ '../../blocks/TextsExpoWebinarsRowWPConnected/TextsExpoWebinarsRowWPConnected'
    ),
);
const CustomerReviewsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "CustomerReviewsConnected" */ '../../blocks/CustomerReviewsConnected/CustomerReviewsConnected'
    ),
);
const MultiPlayerRowConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "MultiPlayerRowConnected" */ '../../blocks/MultiPlayerRowConnected/MultiPlayerRowConnected'
    ),
);

const LibraryCardRowWPConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "LibraryCardRowWPConnected" */ '../../blocks/LibraryCardRowWPConnected/LibraryCardRowWPConnected'
    ),
);

const MediaAboutUsRowWPConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "MediaAboutUsRowWPConnected" */ '../../blocks/MediaAboutUsRowWPConnected/MediaAboutUsRowWPConnected'
    ),
);

const TelegramNewsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "TelegramNewsConnected" */ '../../blocks/TelegramNewsConnected/TelegramNewsConnected'
    ),
);

const GridCardsConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "GridCardsConnected" */ '../../blocks/GridCardsConnected'
    ),
);

const InfoCardConnected = dynamic(
  () =>
    import(
      /* webpackChunkName: "InfoCardConnected" */ '../../blocks/InfoCardConnected/InfoCardConnected'
    ),
);

export type TSectionRowsProps = {
  row: Required<Section>['rows'][number];
  section: Section;
  sectionVariant?: ESectionVariant;
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
  customSectionRowRenderer?: (row: any, section?: Section) => React.ReactNode; // eslint-disable-line @typescript-eslint/no-explicit-any
  handleEmptyContent?: () => void;
  websiteType?: EWebsiteType;
};

export const SectionRow: React.FC<TSectionRowsProps> = props => {
  const {
    row,
    section,
    locales,
    jurisdictionList,
    jurisdictionItem,
    customSectionRowRenderer,
    handleEmptyContent,
    websiteType,
  } = props;

  if (customSectionRowRenderer) {
    const result = customSectionRowRenderer(row, section);

    if (result !== undefined) {
      return result as React.ReactElement;
    }
  }

  switch (row.blockType) {
    case 'faq':
      return <AccordionsGridConnected key={row.id} {...row} />;
    case 'form-slot':
      return <FormSlotConnected key={row.id} {...row} />;
    case 'form-slot-v2':
      return <FormSlotV2Connected key={row.id} {...row} />;
    case 'logo-cloud':
      return <LogoCloudConnected key={row.id} {...row} />;
    case 'stats':
      return <StatsConnected key={row.id} {...row} />;
    case 'stats-v2':
      return <StatsV2Connected key={row.id} {...row} />;
    case 'contacts':
      return <ContactsConnected key={row.id} {...row} />;
    case 'connection-scheme':
      return <ConnectionSchemeConnected key={row.id} {...row} />;
    case 'pricing':
      return (
        <PricingPlanConnected
          locales={locales}
          jurisdictionList={jurisdictionList}
          jurisdictionItem={jurisdictionItem}
          key={row.id}
          {...row}
        />
      );
    case 'feature-grids':
      return <FeaturesGridConnected key={row.id} {...row} />;
    case 'tabbed-cards':
      return <TabbedCardsConnected key={row.id} {...row} />;
    case 'title-card-basic':
      return <TitleBasicCardsConnected key={row.id} {...row} />;
    case 'title-card-bg':
      return <TitleBgCardsConnected key={row.id} {...row} />;
    case 'banner-cards':
      return <BannerCardsConnected key={row.id} {...row} />;
    case 'banner-cards-bg-v2':
      return <BannerCardsBgV2Connected key={row.id} {...row} />;
    case 'horizontal-cards':
      return <HorizontalCardsConnected key={row.id} {...row} />;
    case 'horizontal-cards-v2':
      return <HorizontalCardsV2Connected key={row.id} {...row} />;
    case 'horizontal-card-bg-v2':
      return <HorizontalCardsBgV2Connected key={row.id} {...row} />;
    case 'default-cards':
      return <DefaultCardsConnected key={row.id} {...row} />;
    case 'default-cards-v2':
      return <DefaultCardsV2Connected key={row.id} {...row} />;
    case 'brand-cards':
      return <BrandCardsConnected key={row.id} {...row} />;
    case 'brand-cards-bg-v2':
      return <BrandCardsBgV2Connected key={row.id} {...row} />;
    case 'banner-list':
      return <BannerListConnected key={row.id} {...row} />;
    case 'banner-card-w-title-cards':
      return <BannerCardWithTitleCardsConnected key={row.id} {...row} />;
    case 'recent-articles':
      return (
        <RecentArticlesConnected
          locales={locales}
          jurisdictionList={jurisdictionList}
          jurisdictionItem={jurisdictionItem}
          key={row.id}
          {...row}
        />
      );
    case 'news-block':
      return (
        <NewsBlockConnected
          key={row.id}
          locales={locales}
          jurisdictionList={jurisdictionList}
          jurisdictionItem={jurisdictionItem}
          websiteType={websiteType}
          {...row}
        />
      );
    case 'events-block':
      return (
        <EventCardRowConnected
          locales={locales}
          jurisdictionList={jurisdictionList}
          jurisdictionItem={jurisdictionItem}
          key={row.id}
          handleEmptyContent={handleEmptyContent}
          {...row}
        />
      );
    case 'videos-block':
      return (
        <VideosBlockConnected
          locales={locales}
          jurisdictionList={jurisdictionList}
          jurisdictionItem={jurisdictionItem}
          key={row.id}
          {...row}
        />
      );
    case 'video-player-list':
      return <VideoPlayerListConnected key={row.id} {...row} />;
    case 'documents-list':
      return <DocumentsListConnected key={row.id} {...row} />;
    case 'countries-row':
      return <CountriesRowConnected key={row.id} {...row} />;
    case 'currency-list':
      return <CurrencyListConnected key={row.id} {...row} />;
    case 'finance-table':
      return <FinanceTableConnected key={row.id} {...row} />;
    case 'chart-scrollable':
      return <ChartScrollableConnected key={row.id} {...row} />;
    case 'tree-diagram':
      return <TreeDiagramConnected key={row.id} {...row} />;
    case 'vertical-cards':
      return <VerticalCardsConnected key={row.id} {...row} />;
    case 'quote-card':
      return <QuoteCardConnected key={row.id} {...row} />;
    case 'plates-default-card':
      return <PlatesDefaultCardConnected key={row.id} {...row} />;
    case 'company-rankings-list':
      return <CompanyRankingsListConnected key={row.id} {...row} />;
    case 'card-table-mixed':
      return <CardTableMixedConnected key={row.id} {...row} />;
    case 'cards-with-table':
      return <CardsWithTableConnected key={row.id} {...row} />;
    case 'partners-widget':
      return <PartnersWidgetConnected key={row.id} {...row} />;
    case 'partners-widget-custom-tabs':
      return <PartnersWidgetCustomTabsConnected key={row.id} {...row} />;
    case 'accordion-cards':
      return <AccordionCardsConnected key={row.id} {...row} />;
    case 'accordion-cards-v2':
      return <AccordionCardsV2Connected key={row.id} {...row} />;
    case 'list-of-titles':
      return <ListOfTitlesConnected key={row.id} {...row} />;
    case 'sub-heading':
      return <SubHeadingConnected key={row.id} {...row} />;
    case 'card-with-logo-row':
      return <CardWithLogoRowConnected key={row.id} {...row} />;
    case 'card-with-quote-row':
      return <CardWithQuoteRowConnected key={row.id} {...row} />;
    case 'card-with-stories':
      return <CardWithStoriesConnected key={row.id} {...row} />;
    case 'news-card-row-wp':
      return <NewsCardRowWPConnected key={row.id} {...row} />;
    case 'cell-with-links-row':
      return <CellWithLinksRowConnected key={row.id} {...row} />;
    case 'hero-section-title-row':
      return <HeroSectionTitleRowConnected key={row.id} {...row} />;
    case 'additional-button':
      return <AdditionalButtonConnected key={row.id} {...row} />;
    case 'texts-news-row-wp':
      return <TextsNewsRowWPConnected key={row.id} {...row} />;
    case 'texts-expo-webinars-row-wp':
      return <TextsExpoWebinarsRowWPConnected key={row.id} {...row} />;
    case 'product-cards':
      return <ProductCardsConnected key={row.id} {...row} />;
    case 'customer-reviews':
      return <CustomerReviewsConnected key={row.id} {...row} />;
    case 'multi-player-row':
      return <MultiPlayerRowConnected key={row.id} {...row} />;
    case 'library-card-row-wp':
      return <LibraryCardRowWPConnected key={row.id} {...row} />;
    case 'telegram-news':
      return <TelegramNewsConnected key={row.id} {...row} />;
    case 'media-about-us-row-wp':
      return <MediaAboutUsRowWPConnected key={row.id} {...row} />;
    case 'grid-cards':
      return <GridCardsConnected key={row.id} {...row} />;
    case 'info-card':
      return <InfoCardConnected key={row.id} {...row} />;
    default:
      return null;
  }
};
