import React from 'react';

import Serialize from './Serialize';
import {
  ERichTextBullets,
  ERichTextBulletsTheme,
  ERichTextDefaultTag,
} from './constants';
import { TLeaf } from './types';
import isRichTextContentEmpty from './utils/checkEmptyRichText';
import { processRichText } from './utils/processRichText';
import updateNewTabInContentRichText from './utils/updateNewTabInContentRichText';

export type TRichTextContent = { [k: string]: unknown }[];

export type TRichTextBulletsOptions = {
  bullets?: `${ERichTextBullets}`;
  bulletsTheme?: `${ERichTextBulletsTheme}`;
};

export type TRichTextProps = {
  content?: TLeaf | TLeaf[] | TRichTextContent;
  isOpenInNewTab?: boolean;
  className?: string;
  onOpenModal?: (modalId: string) => void;
  bulletsOptions?: TRichTextBulletsOptions;
  hasWrapper?: boolean;
  defaultTag?: ERichTextDefaultTag;
};

const RichText: React.FC<TRichTextProps> = props => {
  const {
    content,
    isOpenInNewTab,
    className = '',
    onOpenModal,
    hasWrapper = true,
    bulletsOptions = {
      bullets: ERichTextBullets.CircleMark,
      bulletsTheme: ERichTextBulletsTheme.Theme1,
    },
    defaultTag = ERichTextDefaultTag.P,
  } = props;

  const contentArray = processRichText(content);

  let updatedContent: TLeaf[];

  if (isOpenInNewTab) {
    updatedContent = updateNewTabInContentRichText(contentArray as TLeaf[]);
  } else {
    updatedContent = contentArray as TLeaf[];
  }

  const isEmptyContent = isRichTextContentEmpty(updatedContent);

  if (!updatedContent.length || isEmptyContent) {
    return null;
  }
  const serializedContent = Serialize(updatedContent, {
    bullets: bulletsOptions.bullets,
    bulletsTheme: bulletsOptions.bulletsTheme,
    onOpenModal,
    defaultTag,
  });

  return hasWrapper ? (
    <div className={className}>{serializedContent}</div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{serializedContent}</>
  );
};

export default RichText;
