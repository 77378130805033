import { ELibraryCardType } from '../components/atoms/LibraryCard';
import { TNewsCardProps } from '../components/atoms/NewsCard';

const shuffleArray = <T>(array: T[]): T[] => {
  return array
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

const libraryCardTypes = [
  ELibraryCardType.Basic,
  ELibraryCardType.Advanced,
  ELibraryCardType.Professional,
];

const arrangeByLibraryType = (posts: TNewsCardProps[]): TNewsCardProps[] => {
  const typeBuckets: Record<ELibraryCardType, TNewsCardProps[]> = {
    [ELibraryCardType.Basic]: [],
    [ELibraryCardType.Advanced]: [],
    [ELibraryCardType.Professional]: [],
  };

  posts.forEach(post => {
    if (post.libraryType) {
      typeBuckets[post.libraryType].push(post);
    }
  });

  const arrangedPosts: TNewsCardProps[] = [];
  let added = true;

  const shuffledLibraryTypes = shuffleArray(libraryCardTypes);

  const roundRobinDistribute = (): boolean => {
    let hasMorePosts = false;
    shuffledLibraryTypes.forEach(type => {
      if (typeBuckets[type].length > 0) {
        arrangedPosts.push(typeBuckets[type].shift()!);
        hasMorePosts = true;
      }
    });
    return hasMorePosts;
  };

  while (added) {
    added = roundRobinDistribute();
  }

  return arrangedPosts;
};

export default function buildLibraryList(
  newsPosts: TNewsCardProps[],
  allTabTitle?: string,
  shuffle = true,
): {
  tabPosts: { title: string; posts: TNewsCardProps[] }[];
  tabTitles: Record<ELibraryCardType, string>;
} {
  const tabTitle = allTabTitle || 'All';

  const tabPostsMap: Record<ELibraryCardType | string, TNewsCardProps[]> = {
    [tabTitle]: [],
  };

  const tabTitles = new Map();

  tabTitles.set('all', tabTitle);
  tabTitles.set(ELibraryCardType.Basic, '');
  tabTitles.set(ELibraryCardType.Advanced, '');
  tabTitles.set(ELibraryCardType.Professional, '');

  newsPosts.forEach(post => {
    if (post.libraryType && post?.tags?.[0]?.children) {
      tabTitles.set(post.libraryType, post.tags[0].children);
    }
    tabPostsMap[tabTitle].push(post);

    if (Array.isArray(post.tags)) {
      post.tags.forEach(tag => {
        const tagKey = tag.children;
        if (!tabPostsMap[tagKey]) {
          tabPostsMap[tagKey] = [];
        }
        if (typeof tagKey === 'string') {
          tabPostsMap[tagKey].push(post);
        }
      });
    }
  });

  if (shuffle) {
    Object.keys(tabPostsMap).forEach(key => {
      tabPostsMap[key] = arrangeByLibraryType(tabPostsMap[key]);
    });
  }

  const tabPosts: { title: string; posts: TNewsCardProps[] }[] = Array.from(
    tabTitles.values(),
  ).map(title => ({
    title,
    posts: tabPostsMap[title] || [],
  }));

  return {
    tabTitles: Object.fromEntries(tabTitles),
    tabPosts,
  };
}
