import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListItem,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import clsx from 'clsx';
import * as React from 'react';
import { ReactNode } from 'react';

import { ESelectBackgroundVariant, ESelectVariant } from './Select.constants';
import { useSelectOffset } from './useSelectOffset';
import { ReactComponent as IconArrowDown } from '../../../../public/icons/icon-arrow-down.svg';

interface ISelectContextValue {
  activeIndex: number | null;
  selectedIndex: number | null;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  handleSelect: (index: number | null) => void;
}

const SelectContext = React.createContext<ISelectContextValue>(
  {} as ISelectContextValue,
);

export interface ISelectProps {
  variant: ESelectVariant;
  children: ReactNode;
  value: number | null;
  options: { label: string; value: string }[];
  onChange: (value: number | null) => void;
  backgroundVariant?: ESelectBackgroundVariant;
  placeholder?: string;
  label?: string;
  icon?: ReactNode;
  showArrowDown?: boolean;
}

const Select: React.FC<ISelectProps> = props => {
  const {
    children,
    variant,
    backgroundVariant,
    value,
    label,
    options,
    onChange,
    icon,
    placeholder,
    showArrowDown = true,
  } = props;

  const elementsRef = React.useRef<(HTMLElement | null)[]>([]);
  const labelsRef = React.useRef<(string | null)[]>([]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(
    value,
  );

  const [selectedLabel, setSelectedLabel] = React.useState<string | null>(
    value !== null && typeof value !== 'undefined'
      ? options?.[value]?.label
      : null,
  );

  const offset = useSelectOffset(variant);

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    strategy: 'fixed',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset,
      shift({ padding: 16 }),
      size({
        apply({ elements }) {
          Object.assign(elements.floating.style, {
            width: `${(elements.reference as HTMLElement).clientWidth}px`,
          });
        },
      }),
      flip(),
    ],
  });

  const handleSelect = React.useCallback(
    (index: number | null) => {
      setSelectedIndex(index);
      setIsOpen(false);
      if (index !== null) {
        setSelectedLabel(options[index].label);
        onChange(index);
      }
    },
    [onChange, options],
  );

  function handleTypeaheadMatch(index: number | null): void {
    if (isOpen) {
      setActiveIndex(index);
    } else {
      handleSelect(index);
    }
  }

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  });
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    activeIndex,
    selectedIndex,
    onMatch: handleTypeaheadMatch,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [listNav, typeahead, click, dismiss, role],
  );

  const selectContext = React.useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, selectedIndex, getItemProps, handleSelect],
  );

  const inputClasses =
      'h-15 cursor-pointer flex-col justify-center gap-0.5 self-start rounded-xl px-4 text-base font-medium leading-relaxed text-interface-800',
    inputLabelClasses = 'text-sm font-medium text-control-950',
    menuLabelClasses = 'text-xs text-brand-500',
    menuClasses =
      'h-8 flex-row items-center justify-start gap-1 rounded px-3 text-sm font-medium leading-normal hover:bg-control-100',
    widgetClasses =
      'h-14 flex-row items-center justify-start gap-1 rounded-[1.125rem] px-5 py-[1.125rem] text-base font-medium leading-relaxed';

  return (
    <>
      <div
        ref={refs.setReference}
        tabIndex={0}
        className={clsx(
          'flex cursor-pointer select-none transition',
          variant === ESelectVariant.Input && inputClasses,
          variant === ESelectVariant.Menu && menuClasses,
          variant === ESelectVariant.Widget && widgetClasses,
          variant === ESelectVariant.Input &&
            !backgroundVariant &&
            'bg-surface-50-input',
          backgroundVariant === ESelectBackgroundVariant.Primary &&
            'bg-surface-100-input',
          backgroundVariant === ESelectBackgroundVariant.Secondary &&
            'bg-surface-50-input',
          backgroundVariant === ESelectBackgroundVariant.Widget &&
            'bg-control-100',
          variant === ESelectVariant.PhoneInput && ESelectVariant.PhoneInput, // used for blur handling
        )}
        {...getReferenceProps()}
      >
        {label && (
          <span
            className={clsx(
              'leading-loose',
              variant === ESelectVariant.Menu
                ? inputLabelClasses
                : menuLabelClasses,
            )}
          >
            {label}
          </span>
        )}
        <span
          className={clsx(
            'flex max-w-full items-center gap-1 overflow-hidden whitespace-nowrap',
            variant === ESelectVariant.Widget && ' flex-1 justify-between',
          )}
        >
          {icon}
          {variant !== ESelectVariant.PhoneInput &&
            (selectedLabel || placeholder) && (
              <span>{selectedLabel || placeholder}</span>
            )}
          {showArrowDown && (
            <IconArrowDown
              width={10}
              height={10}
              className={clsx(
                'text-control-950 transition-transform duration-300',
                isOpen && 'rotate-180',
              )}
            />
          )}
        </span>
      </div>
      <SelectContext.Provider value={selectContext}>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              className={clsx(
                'z-50 box-border flex w-max flex-col overflow-y-auto bg-surface-50-dropdown text-sm font-medium leading-normal text-control-950',
                variant !== ESelectVariant.Widget &&
                  'max-h-36 min-w-fit rounded-xl shadow-md',
                variant === ESelectVariant.Widget &&
                  'max-h-[18.5rem] rounded-3xl border border-surface-100 shadow-lg',
                variant === ESelectVariant.PhoneInput &&
                  'scrollbar-hidden max-h-[22.25rem] border border-control-600 shadow-none',
                variant !== ESelectVariant.PhoneInput &&
                  'scrollbar scrollbar-w-1 scrollbar-thumb-interface-500 scrollbar-track-control-200 p-2 ',
              )}
              {...getFloatingProps()}
            >
              <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                {children}
              </FloatingList>
            </div>
          </FloatingFocusManager>
        )}
      </SelectContext.Provider>
    </>
  );
};

export default Select;

export interface IOptionProps {
  label: string;
  children: ReactNode;
  hideSelected?: boolean;
  variant?: ESelectVariant;
}

export const Option: React.FC<IOptionProps> = props => {
  const { label, children, hideSelected = false, variant } = props;

  const { activeIndex, selectedIndex, getItemProps, handleSelect } =
    React.useContext(SelectContext);

  const { ref, index } = useListItem({ label });

  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;

  return isSelected && hideSelected ? null : (
    <button
      ref={ref}
      role='option'
      aria-selected={isActive && isSelected}
      tabIndex={isActive ? 0 : -1}
      className={clsx(
        'flex shrink-0 items-center gap-1 outline-none transition hover:bg-control-100',
        isSelected && variant !== ESelectVariant.Widget && 'bg-control-100',
        isSelected && ESelectVariant.Widget && 'bg-control-150',
        variant !== ESelectVariant.Widget &&
          variant !== ESelectVariant.PhoneInput &&
          'h-8 rounded px-2',
        variant === ESelectVariant.Widget && 'h-14 rounded-[1.125rem] p-5',
        variant === ESelectVariant.PhoneInput &&
          'h-16 w-[15rem] border-b border-surface-150 hover:bg-surface-100',
      )}
      {...getItemProps({
        onClick: () => handleSelect(index),
      })}
    >
      <span
        className={clsx(
          'outline-none',
          variant !== ESelectVariant.Widget && 'flex items-center gap-1',
          variant === ESelectVariant.Widget && 'overflow-hidden',
          variant === ESelectVariant.PhoneInput && '!gap-4 tablet:!gap-6',
        )}
      >
        {children}
      </span>
    </button>
  );
};
