import { TNewsCardProps } from '../components/atoms/NewsCard';
import { TTextExpoWebinar } from '../components/atoms/TextExpoWebinarGroup/TextExpoWebinarGroup';

function getMonthName(monthIndex: number, locale: string): string {
  const date = new Date();
  date.setMonth(monthIndex);
  const monthName = new Intl.DateTimeFormat(locale, { month: 'short' }).format(
    date,
  );

  if (locale === 'ru' || locale === 'pt') {
    return monthName.replace('.', '');
  }

  return monthName;
}

function isTextExpoWebinar(
  post: TTextExpoWebinar | TNewsCardProps,
): post is TTextExpoWebinar {
  return (post as TTextExpoWebinar).dateStart !== undefined;
}

export default function buildGroupingExposByMonth(
  newsPosts: TTextExpoWebinar[] | TNewsCardProps[],
  locale = 'en-US',
): Record<string, TTextExpoWebinar[]> {
  return newsPosts.reduce(
    (groups, post) => {
      if (isTextExpoWebinar(post)) {
        const date = new Date(post.dateStart);
        const monthIndex = date.getMonth();
        const monthName = getMonthName(monthIndex, locale);

        if (!groups[monthName]) {
          groups[monthName] = [];
        }

        groups[monthName].push(post);
      }

      return groups;
    },
    {} as Record<string, TTextExpoWebinar[]>,
  );
}
