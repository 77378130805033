import clsx from 'clsx';
import React from 'react';

import {
  descriptionCVA,
  ESectionTitleAlignContent,
  ESectionTitleContentWidth,
  ESectionTitleTheme,
  ESectionTitleType,
  eyebrowsCVA,
  sectionTitleCVA,
} from './SectionTitle.constants';
import Buttons from './components/Buttons';
import Eyebrows from './components/Eyebrows';
import { TSectionTitleProps } from './types';
import baseTailwindConfig from '../../../../tailwind.config.base';
import { useBetterMediaQuery } from '../../../hooks/useBetterMediaQuery';
import { EEyebrowSize, EEyebrowVariant } from '../Eyebrow';
import { ERichTextDefaultTag } from '../RichText';
import RichText from '../RichText/RichText';
import isRichTextContentEmpty from '../RichText/utils/checkEmptyRichText';
import { TitlePart } from '../TitlePart';
import {
  ETitlePartsPosition,
  ETitlePartsSize,
  ETitlePartsTheme,
} from '../TitlePart/TitlePart.constants';

export const SectionTitle: React.FC<TSectionTitleProps> = props => {
  const {
    title,
    richTitle,
    animationClasses,
    titleSpacingY,
    topDescription,
    hasTitleMargin = false,
    description,
    buttons,
    eyebrowsTop,
    eyebrowsBottom,
    titleType = ESectionTitleType.A,
    titleAlignContent,
    titleContentWidth = ESectionTitleContentWidth.Md,
    themeTitle,
  } = props;

  const hasRichTitle = richTitle && !isRichTextContentEmpty(richTitle);
  const isXl = useBetterMediaQuery(
    `(min-width: ${baseTailwindConfig.screens.xl})`,
  );
  const isMd = useBetterMediaQuery(
    `(min-width: ${baseTailwindConfig.screens.md})`,
  );
  const getTitlePartSize = (
    sectionTitleType: ESectionTitleType,
  ): ETitlePartsSize => {
    if (sectionTitleType === ESectionTitleType.A) {
      if (isXl) {
        return ETitlePartsSize.XxL;
      }
      if (isMd) {
        return ETitlePartsSize.Xl;
      }
      return ETitlePartsSize.L;
    } else {
      if (isXl) {
        return ETitlePartsSize.XxxxL;
      }
    }
    return ETitlePartsSize.Xl;
  };
  const getEyebrowsSize = (
    sectionTitleType: ESectionTitleType,
  ): EEyebrowSize => {
    return sectionTitleType === ESectionTitleType.A && isMd
      ? EEyebrowSize.Large
      : EEyebrowSize.Medium;
  };

  const eyebrowsVariant =
    titleType === ESectionTitleType.A
      ? EEyebrowVariant.Text
      : EEyebrowVariant.TextTracking;

  const eyebrowsClassName = eyebrowsCVA({ type: titleType, theme: themeTitle });

  let titlePartTheme = ETitlePartsTheme.Dark;

  switch (themeTitle) {
    case ESectionTitleTheme.SectionTitle1:
      titlePartTheme = ETitlePartsTheme.Dark;
      break;
    case ESectionTitleTheme.SectionTitle2:
      titlePartTheme = ETitlePartsTheme.Dark;
      break;
    case ESectionTitleTheme.SectionTitle3:
      titlePartTheme = ETitlePartsTheme.Light;
      break;
    case ESectionTitleTheme.SectionTitle4:
      titlePartTheme = ETitlePartsTheme.Light;
      break;
    case ESectionTitleTheme.SectionTitle5:
      titlePartTheme = ETitlePartsTheme.Primary;
      break;
    default:
      break;
  }
  const hasContent =
    title ||
    !!eyebrowsTop?.length ||
    !!eyebrowsBottom?.length ||
    description ||
    hasRichTitle;

  if (!hasContent && !buttons?.length) {
    return null;
  }

  return (
    <div
      className={sectionTitleCVA({
        hasTitleMargin,
        titleContentWidth,
        titleType,
        titleSpacingY,
        titleAlignContent,
        className: animationClasses,
      })}
    >
      <Eyebrows
        eyebrows={eyebrowsTop}
        variant={eyebrowsVariant}
        className={eyebrowsClassName}
        hasMarginBottom={titleType === ESectionTitleType.B}
        size={getEyebrowsSize(titleType)}
        alignCenter={titleAlignContent === ESectionTitleAlignContent.Center}
      />
      {title || hasRichTitle ? (
        <TitlePart
          theme={titlePartTheme}
          size={getTitlePartSize(titleType)}
          position={
            titleAlignContent === ESectionTitleAlignContent.Center
              ? ETitlePartsPosition.Center
              : ETitlePartsPosition.Left
          }
        >
          <h2 className='prose-a:inline-block'>
            {hasRichTitle ? (
              <RichText
                content={richTitle}
                hasWrapper={false}
                defaultTag={ERichTextDefaultTag.Span}
              />
            ) : (
              title
            )}
          </h2>
        </TitlePart>
      ) : null}
      <Eyebrows
        eyebrows={eyebrowsBottom}
        variant={eyebrowsVariant}
        className={eyebrowsClassName}
        size={getEyebrowsSize(titleType)}
        alignCenter={titleAlignContent === ESectionTitleAlignContent.Center}
      />
      {topDescription && !isRichTextContentEmpty(topDescription) ? (
        <RichText
          className={descriptionCVA({
            alignContent: titleAlignContent,
            theme: themeTitle,
          })}
          content={topDescription}
        />
      ) : (
        description && (
          <span
            className={descriptionCVA({
              alignContent: titleAlignContent,
              theme: themeTitle,
            })}
          >
            {description}
          </span>
        )
      )}
      <Buttons
        className={clsx(
          'flex flex-wrap items-center gap-x-8 gap-y-6 empty:hidden',
          {
            'mt-5 md:mt-9': titleType === ESectionTitleType.A && hasContent,
            'mt-4 md:mt-8': titleType === ESectionTitleType.B && hasContent,
          },
        )}
        alignCenter={titleAlignContent === ESectionTitleAlignContent.Center}
        buttons={buttons}
      />
    </div>
  );
};
