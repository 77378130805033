export const enum ESectionSpacing {
  None = 'none',
  Xs = 'xs',
  Sm = 'sm',
  Base = 'base',
  Xl = 'xl',
  XxL = '2xl',
}

export const enum ESectionVariant {
  B2Broker = 'b2broker',
  BlockWithRows = 'BlockWithRows',
  Default = 'Default',
}

export const enum ERowSpacing {
  Xs = 'xs',
  Base = 'base',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl',
}

export const enum ESectionBackground {
  Transparent = 'Transparent',
  White = 'White',
  Light = 'Light',
  Dark = 'Dark',
  Theme1 = 'Theme1', // white bg, dark text, brand eyebrows
  Theme2 = 'Theme2', // white bg, dark text, dark eyebrows
  Theme3 = 'Theme3', // dark bg, white text, brand eyebrows
  Theme4 = 'Theme4', // dark bg, white text, white eyebrows
  Theme5 = 'Theme5', // dark bg, brand text, white eyebrows
}

export const enum ETextColor {
  Dark = 'Dark',
  Light = 'Light',
}
