import { TelegramPost, Locale } from '@shared/master-types';

export type TTelegramPostsExtended = TelegramPost & {
  locales?: Locale[];
  slug?: string;
};

export default function buildTelegramPostsList(
  docsList: (string | TTelegramPostsExtended)[] | TelegramPost[] | undefined,
): Array<TTelegramPostsExtended> {
  if (!docsList) {
    console.warn('%c* WARNING *', 'color: orange; font-weight: bold');
    console.warn(
      '%cNo TelegramPostsList provided, check TelegramPosts block in your page',
      'color: red; font-style: italic',
    );
    return [];
  }

  const processedArticles: Array<TTelegramPostsExtended> = [];

  for (const doc of docsList) {
    if (typeof doc === 'string') {
      console.warn('%c* WARNING *', 'color: orange; font-weight: bold');
      console.warn(
        '%cWrong TelegramPosts provided, check TelegramPosts block in your page',
        'color: red; font-style: italic',
      );
      continue;
    }

    const { id, postID, telegramUserName, updatedAt, title, createdAt } = doc;

    processedArticles.push({
      id,
      telegramUserName,
      createdAt,
      title,
      postID,
      updatedAt,
    });
  }

  return processedArticles;
}
