import * as Sentry from '@sentry/react';
import pino from 'pino';
import { useEffect } from 'react';

import { useGetSettingsQuery } from '../../../../features/settings/getSettings.query';
import { useLocale } from '../../../../hooks';

const logger = pino({ level: process.env.LOG_LEVEL || 'info' });
if (process.env.APP_ENV !== 'local') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN_URL,
    tracesSampleRate: 1.0,
  });
}

export const useOneSignal = (): void => {
  const { locale } = useLocale();
  const { data: { oneSignal } = {} } = useGetSettingsQuery({ locale });

  useEffect(() => {
    const {
      appId,
      safariWebId,
      actionMessage,
      acceptButton,
      cancelButton,
      pageViews = 1,
      timeDelay = 30,
    } = oneSignal || {};

    if (appId) {
      const initOneSignal = async (): Promise<void> => {
        try {
          const OneSignal = (await import('react-onesignal')).default;
          await OneSignal.init({
            appId,
            allowLocalhostAsSecureOrigin: true,
            safari_web_id: safariWebId,
            notifyButton: {
              enable: true,
            },
            serviceWorkerParam: {
              scope: '/push/onesignal/js',
            },
            path: '/push/onesignal/',
            promptOptions: {
              slidedown: {
                prompts: [
                  {
                    autoPrompt: true,
                    text: {
                      actionMessage,
                      acceptButton,
                      cancelButton,
                    },
                    delay: {
                      pageViews,
                      timeDelay,
                    },
                  },
                ],
              },
            },
          });
        } catch (e) {
          const errorMessage = e instanceof Error ? e.message : 'Unknown error';
          logger.error(`OneSignal init error: ${errorMessage}`);
          Sentry.captureException(e);
        }
      };

      void initOneSignal();
    }
  }, [oneSignal]);
};

export default useOneSignal;
