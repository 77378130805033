import { getMediaSrc, toLinkParam } from '@front/shared/ds';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import { isSSR } from '../../../utils';

import type { Meta as PayloadMeta } from '@payloadcms/plugin-seo/types';
import type { Media, Meta as IMeta } from '@shared/master-types';

export type TMetaProps = Omit<PayloadMeta, 'image'> & {
  image?: string | Media;
  isPageCanonical?: boolean;
  url?: string;
} & Omit<IMeta, 'createdAt' | 'updatedAt' | 'id'>;

declare const window: Window &
  typeof globalThis & {
    NEXT_APP_HOST: string | undefined;
  };
const Meta: React.FC<TMetaProps> = props => {
  const {
    description,
    favicon,
    image,
    title,
    isPageCanonical,
    url,
    isNoindex = false,
  } = props;

  const router = useRouter();
  const { query } = router;
  const appHost = isSSR() ? process.env.NEXT_APP_HOST : window.NEXT_APP_HOST;
  const linkParam = toLinkParam({
    locale: query?.locale?.toString(),
    jurisdiction: query?.jurisdiction?.toString(),
  });

  const canonical =
    !isPageCanonical &&
    url &&
    appHost &&
    `${appHost}${linkParam && '/'}${linkParam}${url}`;

  return (
    <Head>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title} key='title' />
      <meta property='og:description' content={description} />
      {favicon?.ico && (
        <link rel='icon' href={getMediaSrc(favicon.ico)} sizes='any' />
      )}
      {favicon?.svg && (
        <link rel='icon' href={getMediaSrc(favicon.svg)} type='image/svg+xml' />
      )}
      {typeof image === 'string' && (
        <meta property='og:image' content={getMediaSrc(image)} />
      )}
      {typeof image !== 'string' && image && (
        <>
          <meta property='og:image' content={getMediaSrc(image.url)} />
          <meta property='og:image:alt' content={image.alt} />
        </>
      )}
      {canonical && <link rel='canonical' href={canonical} />}
      {isNoindex && <meta name='robots' content='noindex, nofollow' />}
    </Head>
  );
};

export default Meta;
