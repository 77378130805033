import { Middleware, offset } from '@floating-ui/react';
import { baseTailwindConfig } from '@front/shared';
import { useMedia } from 'react-use';

import { ESelectVariant } from './Select.constants';

export const useSelectOffset = (variant: ESelectVariant): Middleware => {
  const isTabletBreakpoint = useMedia(
    `(min-width: ${baseTailwindConfig.screens.tablet})`,
    false,
  );
  const isDesktopBreakpoint = useMedia(
    `(min-width: ${baseTailwindConfig.screens.desktop})`,
    true,
  );

  if (variant !== ESelectVariant.PhoneInput) {
    return offset(12);
  }

  if (isDesktopBreakpoint) {
    return offset({
      alignmentAxis: -29,
      mainAxis: 34,
    });
  }

  if (isTabletBreakpoint) {
    return offset({
      alignmentAxis: -22,
      mainAxis: 25,
    });
  }

  return offset({
    alignmentAxis: -17,
    mainAxis: 18,
  });
};
