import { getGaId } from '@front/shared';
import {
  Button,
  EActionType,
  EButtonSize,
  EButtonTheme,
  EEyebrowSize,
  EEyebrowVariant,
  ESimpleIconSize,
  ETitlePartsSize,
  Eyebrow,
  Icon,
  TActionWithText,
  TitlePart,
} from '@front/shared/ds';
import { cva } from 'cva';
import React from 'react';

import { EHeroCardTheme } from './constants';

import type { ImageProps } from 'next/image';

const cardCVA = cva(
  'flex min-h-[520px] flex-col justify-between gap-8 rounded-4xl px-4 py-8 md:px-12 md:pb-12 lg:gap-12',
  {
    variants: {
      theme: {
        [EHeroCardTheme.HeroCard1]: 'bg-accent-500',
        [EHeroCardTheme.HeroCard2]: 'bg-accent-550',
        [EHeroCardTheme.HeroCard3]: 'bg-surface-100',
      },
    },
  },
);

export type THeroCardLeftProps = {
  icon?: ImageProps | null;
  title: React.JSX.Element | string | null;
  description?: React.JSX.Element | string;
  eyebrowsTop?: string[];
  eyebrowsBottom?: string[];
  actions?: TActionWithText[];
  theme?: EHeroCardTheme;
};

export const HeroCardLeft: React.FC<THeroCardLeftProps> = props => {
  const {
    icon,
    title,
    description,
    eyebrowsTop,
    eyebrowsBottom,
    actions,
    theme = EHeroCardTheme.HeroCard1,
  } = props;

  return (
    <div className={cardCVA({ theme })}>
      <div className='flex flex-col gap-6'>
        {icon && <Icon size={ESimpleIconSize.XxL} icon={icon} />}

        {!!eyebrowsTop?.length && (
          <div className='flex flex-row flex-wrap gap-6'>
            {eyebrowsTop.map((eyebrow, index) => (
              <Eyebrow
                key={eyebrow + index}
                variant={EEyebrowVariant.TextTracking}
                size={EEyebrowSize.Medium}
              >
                {eyebrow}
              </Eyebrow>
            ))}
          </div>
        )}

        <TitlePart
          size={ETitlePartsSize.XxL}
          tag='h1'
          className='font-normal xl:text-10xl xl:leading-normal'
        >
          {title}
        </TitlePart>

        {!!eyebrowsBottom?.length && (
          <div className='flex flex-row flex-wrap gap-6'>
            {eyebrowsBottom.map((eyebrow, index) => (
              <Eyebrow
                key={eyebrow + index}
                variant={EEyebrowVariant.TextTracking}
                size={EEyebrowSize.Medium}
              >
                {eyebrow}
              </Eyebrow>
            ))}
          </div>
        )}

        {description && (
          <h2 className='max-w-[608px] whitespace-pre-line text-base leading-mega-loose text-interface-800'>
            {description}
          </h2>
        )}
      </div>

      {actions?.length ? (
        <div className='flex flex-row flex-wrap items-center gap-4'>
          {actions.map((action, index) => (
            <React.Fragment key={index}>
              {[EActionType.ButtonType, EActionType.LinkType].includes(
                action.type,
              ) &&
                'props' in action && (
                  <Button
                    theme={EButtonTheme.Tertiary}
                    size={EButtonSize.Large}
                    {...action.props}
                    data-ga-id={getGaId(action.dataGaId || action.text, 'hero')}
                  />
                )}

              {action.type === EActionType.Text && (
                <div className='text-lg font-medium leading-normal text-interface-500 2xl:px-4'>
                  {action.text}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      ) : null}
    </div>
  );
};
