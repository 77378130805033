export const LiquidityConstants = [
  { colorName: '--color-accent-500', color: '238 249 246' },
  { colorName: '--color-header-logo-text', color: '31 34 40' },
  { colorName: '--color-header-back-button', color: '181 183 193' },
  { colorName: '--color-header-icon', color: '181 183 193' },
  { colorName: '--color-header-text-button', color: '31 34 40' },
  { colorName: '--color-header-button-hover', color: '255 255 255' },
  { colorName: '--color-header-chevron', color: '181 183 193' },
  { colorName: '--color-header-background', color: '238 249 246' },
  { colorName: '--color-header-divider', color: '229 229 232' },
  { colorName: '--color-header-submenu-divider', color: '229 229 232' },
  { colorName: '--color-header-submenu-link-title', color: '31 34 40' },
  { colorName: '--color-header-submenu-content', color: '83 86 92' },
  { colorName: '--color-header-submenu-card-hover', color: '246 246 249' },
  { colorName: '--color-header-submenu-title', color: '182 183 188' },
];
