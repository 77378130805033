export enum EModalBackground {
  Light = 'Light',
  Dark = 'Dark',
  Anchors = 'Anchors',
  Transparent = 'Transparent',
  White = 'White',
}

export enum EModalPadding {
  Small = 'Small',
  Large = 'Large',
  Medium = 'Medium',
}

export enum EModalVariant {
  V1 = 'v1',
  V2 = 'v2',
}
