import React, { useEffect, useRef } from 'react';
import { EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';

import { MediaSlide } from './MediaSlide';

import type { Media } from '@shared/master-types';
import type { ImageProps } from 'next/image';

type TMediaSlide = {
  image?: ImageProps | null;
  video?: Media | string | null;
  hasMask?: boolean;
};

type TMediaSlidesProps = {
  slides: TMediaSlide[];
  activeIndex: number;
};

export const MediaSlides: React.FC<TMediaSlidesProps> = ({
  slides,
  activeIndex,
}) => {
  const sliderRef = useRef<SwiperRef>(null);

  useEffect(() => {
    sliderRef.current?.swiper.slideTo(activeIndex);
  }, [activeIndex]);

  return (
    <Swiper
      ref={sliderRef}
      className='absolute left-0 top-0 h-full w-full'
      modules={[EffectCoverflow]}
      effect='coverflow'
      allowTouchMove={false}
      coverflowEffect={{
        slideShadows: false,
        rotate: 0,
        depth: 0,
      }}
      // use 'coverflow' effect width speed=1 instead of 'fade'
      // since 'fade' effect will hurt performance
      speed={1}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <MediaSlide
            key={index}
            slide={slide}
            isActive={activeIndex === index}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
